import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutConfigurationDataService {

    constructor() { }

    hideNavbar$ = new BehaviorSubject<boolean>(true);
    showRootComponents$ = new BehaviorSubject<boolean>(true);
    requiresLogin$ = new BehaviorSubject<boolean>(true);
    showNotification$ = new BehaviorSubject<boolean>(true);

    setHideNavbar(val: boolean){
        this.hideNavbar$.next(val);
    }

    setShowRootComponents(val: boolean){
        this.showRootComponents$.next(val);
    }

    setRequiresLogin(val: boolean){
        this.requiresLogin$.next(val);
    }

    setShowNotification(val: boolean){
        this.showNotification$.next(val);
    }
}
