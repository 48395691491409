
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { LayoutConfigurationDataService } from './layout-configuration-data.service';

@Injectable({
    providedIn: 'root'
})
export class LayoutConfigurationDataResolverService implements Resolve<void> {

    constructor(private layoutConfigurationDataService: LayoutConfigurationDataService) { }

    resolve(route: ActivatedRouteSnapshot): void {
        const hideNavbar = route.data['hideNavbar'];
        const showRootComponents = route.data['showRootComponents'];
        const requiresLogin = route.data['requiresLogin'];
        const showNotification = route.data['showNotification'];
        
        this.layoutConfigurationDataService.setHideNavbar(hideNavbar);
        this.layoutConfigurationDataService.setShowRootComponents(showRootComponents);
        this.layoutConfigurationDataService.setRequiresLogin(requiresLogin);
        this.layoutConfigurationDataService.setShowNotification(showNotification);
    }
}
